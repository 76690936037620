/* Here we are using a sprite sheet for the cards. We are going with order hearts diamond club then spade
* The spritesheet has 2 -> Ace.
*/

/* All hearts (have top of 0)*/
/* 
.card {
    display: block;
    box-sizing: border-box;
    border: 0.1rem black solid;
    border-radius: 5px;
    background: no-repeat white;
    background-size: contain;
    background-position: center;
} */

.card {
    width: calc(67.857vmin - 3.571rem);
    height: calc(95vmin - 5rem);
    display: block;
    margin: 0px;
    border: 0.1rem black solid;
    border-radius: 5px;
    transition: position .25s;
    background: no-repeat white;
    background-size: contain;
}

.card-hidden {
    border: none;
}