.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 1rem;
}